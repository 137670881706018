import React from 'react';
import bag from "../assets/bag.png";
import locationIcon from "../assets/location-icon.svg";
import phoneIcon from "../assets/call-icon.svg";
import emailIcon from "../assets/email-icon.svg";
import contactVector from "../assets/contact-vector.svg";

const ContactPage = () => {
    return (<div className='relative flex flex-col w-full  overflow-hidden pb-4'>

        <div className='left-0 top-0 w-full h-full -z-10'>
            <img
                src={bag}
                className='absolute opacity-80 blur-lg'
                style={{
                    height: '700px', right: '-20%', top: '-1%'
                }}
            />
            <img
                src={bag}
                className='absolute opacity-80 blur-lg'
                style={{
                    height: '800px', left: '-20%',
                }}
            />
        </div>


        <div className='z-10 pt-10 pb-4 px-6 sm:px-12 md:px-48 flex flex-col space-y-3 sm:space-y-5 md:space-y-8 items-center  '>
            <h1 className="text-primary font-bold text-xl sm:text-2xl md:text-3xl ">Contact Us</h1>
            <div
                className="w-full bg-[#F5F5F5] rounded-xl sm:rounded-2xl md:rounded-3xl shadow-lg p-8 flex flex-col-reverse md:flex-row justify-between   gap-4">

                <div className='visible md:hidden '>
                    {contactTiles()}
                </div>

                <div className="flex flex-col space-y-2 md:space-y-4 md:w-1/2 text-center md:text-start">
                    <h2 className="text-2xl font-bold">Get In Touch</h2>
                    <p className='px-8 md:px-0'>We are here for you! How can we help?</p>
                    <form className="flex flex-col space-y-4">
                        <input
                            type="text"
                            placeholder="Enter your name"
                            className="bg-white  rounded-2xl p-3 focus:outline-none  font-bold placeholder:font-light placeholder:text-sm"
                        />
                        <input
                            type="email"
                            placeholder="Enter your email address"
                            className="bg-white  rounded-2xl p-3 focus:outline-none  font-bold placeholder:font-light placeholder:text-sm"
                        />
                        <textarea
                            placeholder="Go ahead, We are listening..."
                            className="bg-white  rounded-xl  p-3 h-32 focus:outline-none  font-bold placeholder:font-light placeholder:text-sm"
                        />
                        <button
                            type="submit"
                            className="bg-secondary  font-bold py-2 rounded-full text-primary "
                        >
                            Submit
                        </button>
                    </form>
                </div>
                <div className="md:w-1/2 flex flex-col justify-center items-center">
                    <img
                        src={contactVector}
                        alt="Illustration"
                        className="h-48"
                    />

                    <div className='hidden md:inline'>
                        {contactTiles()}
                    </div>

                </div>


            </div>
        </div>
    </div>);
};


const contactTiles = () => <div className="mt-2 space-y-4 ">

    <div className="flex items-center space-x-3">
        <img
            src={locationIcon}
            className="h-8"
        />
        <a
            href="https://maps.app.goo.gl/bLR42KfM3rPHYWnX8?g_st=com.google.maps.preview.copy"
            target="_blank"
            rel="noopener noreferrer"
            className=" hover:underline"
        >
            Social Development Bank - Avenue 30
        </a>
    </div>
    <div className="flex items-center space-x-3">
        <img
            src={phoneIcon}
            className="h-8"
        />
        <a
            href="tel:0580448276"
            className=" hover:underline"
        >
            0580448276
        </a>
    </div>
    <div className="flex items-center space-x-3">
        <img
            src={emailIcon}
            className="h-8"
        />
        <a
            href="mailto:ask@solotec.sa"
            className=" hover:underline"
        >
            ask@solotec.sa
        </a>
    </div>

</div>


export default ContactPage;
