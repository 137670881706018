import React from 'react';
import {useParams} from "react-router-dom";
import projects from "../data/projects";
import bag from "../assets/bag.png";
import kitchen from "../assets/kitchen.svg";
import mockup1 from "../assets/projects/hajOs/mockup1.png";
import adminIcon from "../assets/admin.svg";
import guideIcon from "../assets/guide.svg";

const ProjectPage = () => {
    const {id} = useParams();

    const project = projects.find(p => p.id === parseInt(id));

    return (
        <div className="bg-white flex flex-col w-full relative overflow-hidden">
            <div className="absolute left-0 top-0 w-full h-full -z-10">
                <img
                    src={bag}
                    className="absolute opacity-70 blur-lg"
                    style={{
                        height: '700px',
                        right: '-20%',
                        top: '22%',
                    }}
                />
                <img
                    src={bag}
                    className="absolute opacity-60 blur-lg"
                    style={{
                        height: '700px',
                        right: '-24%',
                        top: '70%',
                    }}
                />
                <img
                    src={bag}
                    className="absolute opacity-40 blur-lg"
                    style={{
                        height: '800px',
                        left: '-10%',
                        top: '80%',
                    }}
                />
                <img
                    src={bag}
                    className="absolute opacity-60 blur-md"
                    style={{
                        height: '800px',
                        left: '-25%',
                        top: '40%',
                    }}
                />
            </div>

            <div className="flex flex-col w-full relative overflow-hidden">


                <div className="absolute left-0 top-0 w-full h-full">
                    <img
                        src={bag}
                        className="absolute opacity-50 blur-2xl"
                        style={{
                            height: '700px',
                            right: '-24%',
                            bottom: '-20%',
                        }}
                    />
                    <img
                        src={bag}
                        className="absolute opacity-30 blur-2xl"
                        style={{
                            height: '500px',
                            left: '500px',
                            top: '30%',
                        }}
                    />
                    <img
                        src={bag}
                        className="absolute opacity-50 blur-2xl"
                        style={{
                            height: '800px',
                            top: '-10%',
                            left: '-30%',
                        }}
                    />
                </div>


                <div
                    className="w-full h-[75vh] sm:h-[90vh] bg-primary flex flex-col text-white text-center pb-20
               px-4 sm:px-8 md:px-24 lg:px-48 space-y-4 sm:space-y-6 md:space-y-10 justify-between">
                    <div/>
                    <div>
                        <h1 className="uppercase text-secondary text-xl sm:text-2xl md:text-4xl mt-2">
                            {project.title}
                        </h1>
                        <h1 className="uppercase font-light text-lg sm:text-xl md:text-3xl mt-4">
                            {project.summary}
                        </h1>
                    </div>

                    {Array.isArray(project.images) ? (
                        <div className="relative h-[90%] flex justify-center items-end">
                            <img
                                src={project.images[0]}
                                className="absolute bottom-[-30%] max-w-full max-h-[130%] object-contain"
                            />
                        </div>
                    ) : (
                        <img
                            src={project.image}
                            className="max-w-full max-h-[70%] object-contain mx-auto"
                        />
                    )}
                </div>



            </div>

            {/* Content */}
            <div className="py-6 sm:py-8 md:py-16 px-4 sm:px-10 md:px-20">
                {/* About App */}
                <div>
                    <div className="flex items-center mb-4 md:mb-6">
                        <div
                            className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 bg-secondary -mr-3 sm:-mr-4 md:-mr-6"></div>
                        <h1 className="text-primary font-medium text-lg sm:text-xl md:text-2xl">About App</h1>
                    </div>

                    <div className="flex flex-col md:flex-row items-start space-y-6 md:space-y-0 md:space-x-6">
                        <img src={mockup1} className="w-8/10 self-center md:w-1/3 object-contain" alt="Hajj OS Mockup"/>

                        <div
                            className="w-full md:w-2/3 text-left text-sm sm:text-base md:text-xl font-light space-y-3 leading-6 sm:leading-7 md:leading-10">
                        <p>
                                <strong className="font-semibold">The Hajj OS application</strong> has been launched to
                                streamline internal operations and data control, facilitating and contributing to rapid
                                information retrieval. It also allows officials to closely monitor and supervise all
                                work
                                details in real time.
                            </p>
                            <p className="font-semibold">App Sections</p>
                            <p>The application is divided into three main sections:</p>
                            <ul className="list-inside list-decimal">
                                <li>Administrator Account</li>
                                <li>Receptionist Account</li>
                                <li>Guide Account</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Key Roles */}
                <div className="flex flex-col items-start my-6">
                    <div className="flex items-center mb-4 md:mb-6">
                        <div
                            className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 bg-secondary -mr-3 sm:-mr-4 md:-mr-6"></div>
                        <h1 className="text-primary font-medium text-lg sm:text-xl md:text-2xl">Key Roles</h1>
                    </div>

                    <div
                        className="w-full flex flex-col md:flex-row mt-6 sm:mt-8 md:mt-12 space-y-4 sm:space-y-6 md:space-y-0 md:space-x-8">
                        {/* Administrator Role */}
                        <div
                            className="hover:shadow-lg shadow-sm transition rounded-xl p-4 sm:p-6 flex flex-col items-start space-x-4">
                            <div
                                className="flex flex-row space-x-3 sm:space-x-4 md:space-x-6 justify-center items-center">
                                <div className="bg-[#6E866E] p-3 sm:p-4 rounded-md">
                                    <img src={adminIcon} alt="Administrator Role Icon"
                                         className="h-6 w-6 sm:h-8 sm:w-8 md:h-12 md:w-12"/>
                                </div>
                                <h2 className="text-[#6E866E] font-bold text-lg sm:text-xl md:text-2xl">
                                    ADMINISTRATOR<span className="text-primary"><br/>ROLE</span>
                                </h2>
                            </div>
                            <ul className="font-light leading-6 sm:leading-7 md:leading-10 list-inside mt-4 md:mt-8 space-y-2 text-sm md:text-base">
                                <li>View all data details such as:</li>
                                <li>Number of hotels</li>
                                <li>Direct and indirect communication with guides and staff</li>
                                <li>Number of pilgrims in each hotel</li>
                                <li>Knowing the locations of the pilgrims</li>
                                <li>Knowing the locations of reception staff</li>
                                <li>Track upcoming and departing flights</li>
                                <li>Assign tasks to reception staff</li>
                                <li>Manage all data related to pilgrim and staff details</li>
                            </ul>
                        </div>

                        {/* Guide Role */}
                        <div
                            className="hover:shadow-lg shadow-sm transition rounded-xl p-4 sm:p-6 flex flex-col items-start">
                            <div
                                className="flex flex-row space-x-3 sm:space-x-4 md:space-x-6 justify-center items-center">
                                <div className="bg-[#E2B13C] p-3 sm:p-4 rounded-md">
                                    <img src={guideIcon} alt="Guide Role Icon"
                                         className="h-6 w-6 sm:h-8 sm:w-8 md:h-12 md:w-12"/>
                                </div>
                                <h2 className="text-[#E2B13C] font-bold text-lg sm:text-xl md:text-2xl">
                                    GUIDE<span className="text-primary"><br/>ROLE</span>
                                </h2>
                            </div>
                            <ul className="font-light leading-6 sm:leading-7 md:leading-10 list-inside mt-4 md:mt-8 space-y-2 text-sm md:text-base">
                                <li>View all data details such as:</li>
                                <li>Number of hotels</li>
                                <li>Direct and indirect communication with guides and staff</li>
                                <li>Number of pilgrims in each hotel</li>
                                <li>Knowing the locations of the pilgrims</li>
                                <li>Knowing the locations of reception staff</li>
                                <li>Track upcoming and departing flights</li>
                                <li>Assign tasks to reception staff</li>
                                <li>Manage all data related to pilgrim and staff details</li>
                            </ul>
                        </div>
                    </div>

                    {/* Receptionist Role */}
                    <div className="flex flex-col items-center mt-6 sm:mt-8 md:mt-12 ">
                        <div
                            className="self-start flex flex-row space-x-3 sm:space-x-4 md:space-x-6 justify-center items-center">
                            <div className="bg-primary p-3 sm:p-4 rounded-xl">
                                <img src={kitchen} alt="Receptionist Role Icon"
                                     className="h-8 w-8 sm:h-12 sm:w-12 md:h-16 md:w-16"/>
                            </div>
                            <h2 className="text-primary font-bold text-lg sm:text-xl md:text-2xl">
                                RECEPTIONIST<br/>ROLE
                            </h2>
                        </div>
                        <ul className="list-none mt-4 space-y-2 text-sm md:text-lg font-light leading-6 sm:leading-7 md:leading-9">
                            <li>• Know the arrival times of pilgrims according to the airport</li>
                            <li>• Know the distribution of pilgrims to hotels and the number of pilgrims in each hotel
                            </li>
                            <li>• Direct and indirect communication with officials and guides</li>
                            <li>• Scan, match and accommodate incoming pilgrim data through the application</li>
                        </ul>
                    </div>
                </div>

                {/* Key Features */}
                <div>

                    <div className="flex items-center mb-4 md:mb-6">
                        <div
                            className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 bg-secondary -mr-3 sm:-mr-4 md:-mr-6"></div>
                        <h1 className="text-primary font-medium text-lg sm:text-xl md:text-2xl">Key Features</h1>
                    </div>

                    {project.image && (
                        <img src={project.image}
                             className="pt-8 px-4 sm:px-20 md:px-40 object-contain max-h-60 sm:max-h-80 md:max-h-96 mx-auto"/>
                    )}

                    {
                        /*
                        project.images && (
                        <div className="flex flex-wrap justify-center mt-8 space-x-4">
                            {project.images.map((image, index) => (
                                <div key={index} className="w-full sm:w-1/2 md:w-1/3 p-2">
                                    <img src={image} className="object-contain max-h-40 sm:max-h-60 md:max-h-80 w-full" />
                                </div>
                            ))}
                        </div>
                    )
                         */
                    }


                    {
                        project.images && (
                            <div
                                className=' my-8 flex flex-row space-y-4 sm:space-y-0    sm:space-x-4 sm:justify-between overflow-x-auto'>
                                <div className='flex flex-nowrap sm:flex-wrap space-x-4  '>
                                    {project.images.map((image, index) => (
                                        <div key={index} id={image} className='flex-shrink-0 sm:flex-shrink'>
                                            <img src={image}
                                                 className='h-auto max-h-80 sm:max-h-96 object-contain'/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    }


                </div>
            </div>
        </div>
    );
};

export default ProjectPage;
