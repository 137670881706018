import React from 'react';
import bag from "../assets/bag.png";
import projects from "../data/projects";
import {Link} from "react-router-dom";

const OurProjectsPage = () => {
    return (
        <div className='flex flex-col w-full relative overflow-hidden'>
            <div className='left-0 top-0 w-full h-full -z-10'>
                <img
                    src={bag}
                    className='absolute opacity-80 blur-lg'
                    style={{
                        height: '50vh',
                        right: '-24%',
                        top: '20%'
                    }}
                />

                <img
                    src={bag}
                    className='absolute opacity-60 blur-lg -z-10'
                    style={{
                        height: '50vh',
                        right: '-24%',
                        top: '70%'
                    }}
                />

                <img
                    src={bag}
                    className='absolute opacity-40 blur-lg -z-10'
                    style={{
                        height: '60vh',
                        left: '-30%',
                    }}
                />

                <img
                    src={bag}
                    className='absolute opacity-40 blur-lg -z-10'
                    style={{
                        height: '60vh',
                        left: '-35%',
                        top: '90%'
                    }}
                />

                <img
                    src={bag}
                    className='absolute opacity-40 blur-lg -z-10'
                    style={{
                        height: '60vh',
                        left: '-30%',
                        top: '50%'
                    }}
                />
            </div>

            <div className='py-10 px-8 sm:px-12 md:px-16 lg:px-24 flex flex-col space-y-8 items-center'>
                <h1 className='text-primary font-bold text-2xl sm:text-3xl'>Our Projects</h1>
                {
                    projects.map((project) => (
                        <div key={project.title} id={project.title} className='items-center flex flex-col w-full '>
                            <div className="flex items-start self-start">
                                <div className="w-8 h-8 sm:w-10 sm:h-10 bg-secondary -mr-4 sm:-mr-6 "></div>
                                <h1 className="text-primary font-medium text-xl sm:text-2xl">{project.title}</h1>
                            </div>

                            <p className='text-base sm:text-lg md:text-xl mt-4 font-light leading-7 sm:leading-8 md:leading-9'>
                                {project.content}
                            </p>

                            {project.image && (
                                <img src={project.image} className='pt-8 px-4 sm:px-16 md:px-24 lg:px-40'/>
                            )}


                            {
                                project.images && (
                                    <div
                                        className=' my-8 flex flex-row space-y-4 sm:space-y-0    sm:space-x-4 sm:justify-between overflow-x-auto'>
                                        <div className='flex flex-nowrap sm:flex-wrap space-x-4  '>
                                            {project.images.map((image, index) => (
                                                <div key={index} id={image} className='flex-shrink-0 sm:flex-shrink'>
                                                    <img src={image}
                                                         className='h-auto max-h-80 sm:max-h-96 object-contain'/>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                            }


                            <Link to={`/projects/${project.id}`}>
                                <button
                                    type="submit"
                                    className="bg-primary font-medium py-2 rounded-full text-white px-8 sm:px-10 mt-4"
                                >
                                    Show more
                                </button>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default OurProjectsPage;
