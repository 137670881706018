import React from 'react';
import coding from '../assets/coding.png'
import stats from '../assets/stats.png'
import like from '../assets/like.png'
import paint from '../assets/paint.png'
import crown from '../assets/crown.png'
import check from '../assets/check.png'

const WebDev = () => {
    return (
        <section className='flex flex-col mt-8 sm:pt-16 px-6 md:px-20 space-y-4 md:space-y-10 mb-6 '>


            <div className='w-full text-3xl  leading-normal flex flex-row justify-between items-center  '>

                <div className="flex items-center">
                    <div className="w-10 h-10 bg-secondary -mr-6"></div>
                    <h1 className="text-primary">Web <span className="font-bold">Development</span></h1>
                </div>

                <div className="flex items-center  ">
                    <h1 className="text-sm -mr-4 z-10">2024</h1>
                    <div className="w-6 h-6 bg-[#F0EEF1] "></div>
                </div>
            </div>


            <div className='flex flex-col space-y-4 sm:space-y-6 md:space-y-10'>
                <p className='text-[#5A5A5A]'>
                    Enhance your strategic approach with Vision. Master KPIs,<br/>monitor progress, and make confident
                    decisions.
                </p>

                <div className='w-full grid grid-cols-2 gap-3 md:gap-5 h-[400px] sm:h-[500px] md:h-[600ps] '>

                    <div className=' h-full w-full row-span-1 sm:row-span-2 rounded-2xl overflow-hidden '>
                        <img src={coding} className='object-cover w-full h-full'/>
                    </div>

                    <div className=' h-full w-full rounded-2xl overflow-hidden '>
                        <img src={stats} className='object-cover w-full h-full'/>
                    </div>

                    <div
                        className='w-full h-full col-span-2 sm:col-span-1 bg-white rounded-2xl border flex flex-col px-6 py-4 justify-between'>

                        <div className='bg-[#F0EEF1] text-primary text-sm font-light py-2 px-6 self-start rounded-full'>
                            Design
                        </div>

                        <h1 className='text-2xl'>Upgrading to a <br/>modern CMS - Figma</h1>


                        <button className='bg-primary text-white text-sm font-thin py-2 px-6 self-end rounded-full'>
                            Show more
                        </button>
                    </div>
                </div>
            </div>

            <FeaturesPart/>

        </section>
    );
};


const FeaturesPart = () => {
    const features = [
        {
            icon: like,
            title: <h1>Power up your marketing</h1>,
            values: [
                "Personalize your customer experience",
                "Engage prospects and customers across multiple communication channels",
            ]
        }, {
            icon: crown,
            title: <h1>Supercharged your sales</h1>,
            values: [
                "Scale your efforts without increasing employee",
                "Generate and qualify leads automatically",
            ]
        },
        {
            icon: paint,
            title: <h1>Optimize your supports</h1>,
            values: [
                "Engage prospects and customers across multiple communication",
                "Personalize your customer experience",
            ]
        },

    ]
    return <div className='bg-[#F0EEF1] w-full p-4 sm:p-6 md:p-10 rounded-2xl gap-4 sm:gap-6 md:gap-10 flex flex-col lg:flex-row'>
        {
            features.map((e) =>
                <div id={e.title}
                     className='w-full rounded-2xl bg-white shadow hover:shadow-xl p-4 md:p-8 space-y-6 flex flex-col items-center lg:items-start'>
                    <img src={e.icon} className='h-20 w-20'/>
                    <h1 className='uppercase font-semibold text-xl lg:mr-28'>
                        {e.title}
                    </h1>

                    {e.values.map((value) => <div id={value}
                                                  className='flex flex-row space-x-4 font-thin text-[#7E7E7E] self-start '>
                        <img src={check} className='h-6 w-6 mt-2'/>
                        <p>{value}</p>
                    </div>)
                    }
                </div>
            )
        }
    </div>;
}

export default WebDev;