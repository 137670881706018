import React from 'react';
import headerVector from '../assets/header-vector.svg'
import bag from "../assets/bag.png";

const HeaderSection = () => {
    return (
        <div className=' flex flex-col w-full relative overflow-hidden'>

            <div className='left-0 top-0 w-full h-full'>
                <img
                    src={bag}
                    className='absolute opacity-50 blur-2xl'
                    style={{
                        height: '700px',
                        right: '-24%',
                        bottom: '-20%'
                    }}
                />


                <img
                    src={bag}
                    className='absolute opacity-30 blur-2xl '
                    style={{
                        height: '500px',
                        width: 'full',
                        left: '500px',
                        top: '30%',
                    }}
                />

                <img
                    src={bag}
                    className='absolute opacity-50 blur-2xl'
                    style={{
                        height: '800px',
                        top: '-10%',
                        left: '-30%',
                    }}
                />

            </div>

            <div className=' w-full min-h-screen bg-primary flex flex-col text-white pt-5 px-5'>
                <img src={headerVector} className='w-7/10 sm:w-1/2 lg:w-1/4  self-center'/>
                <h1 className='uppercase  text-2xl sm:text-3xl md:text-5xl text-center self-center mt-8'>
                    Your <span className='text-secondary'>digital</span> needs is here
                </h1>

                <p className='text-lg font-thin px-4 text-center sm:text-start  md:px-20 lg:px-40 mt-3'>
                    A distinguished technical team seeking to transform future technology into contemporary reality. We
                    have
                    extensive experience and comprehensive knowledge in all aspects of software development, and we are
                    distinguished by providing high-quality services that always exceed our customers’ expectations.
                </p>


                <button className='rounded-lg text-lg  bg-secondary self-center py-1 px-7 mt-4 mb-10'>
                    Services
                </button>
            </div>


        </div>
    );
};

export default HeaderSection;