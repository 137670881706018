import React from 'react';
import bag from "../assets/bag.png";
import privacyVector from "../assets/privacy-vector.svg";

const PrivacyPolicyPage = () => {
    return (
        <div className='flex flex-col w-full relative overflow-hidden pb-10'>

            <div className='left-0 top-0 w-full h-full -z-10'>
                <img
                    src={bag}
                    className='absolute opacity-80 blur-lg'
                    style={{
                        height: '50vh',
                        right: '-24%',
                        bottom: '-10%'
                    }}
                />

                <img
                    src={bag}
                    className='absolute opacity-80 blur-lg'
                    style={{
                        height: '60vh',
                        left: '-30%',
                    }}
                />
            </div>

            <div className='py-10 px-8 sm:px-12 md:px-16 lg:px-24 flex flex-col space-y-8'>
                <div className="flex items-center">
                    <div className="w-8 h-8 sm:w-10 sm:h-10 bg-secondary -mr-4 sm:-mr-6"></div>
                    <h1 className="text-primary font-medium text-xl sm:text-2xl">Privacy Policy</h1>
                </div>

                <p className='text-base sm:text-lg md:text-xl lg:text-2xl leading-7 sm:leading-8 md:leading-9 lg:leading-10'>
                    <img
                        src={privacyVector}
                        className="h-40 sm:h-52 md:h-64 lg:h-72 float-right mb-4 ml-4"
                    />
                    Lorem Ipsum is simply dummy text of the printing Ipsum has been the industry's standard dummy text
                    unknown printer took a galley of type and scrambled book. It has survived not only five centuries,
                    but typesetting, remaining essentially unchanged. It was the release of Letraset sheets containing
                    Lorem Ipsum with desktop publishing software like Aldus PageMaker Ipsum publishing software like Aldus
                    PageMaker. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                    been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book. It has survived not only five centuries, but also
                    the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing software like Aldus PageMaker including versions of Lorem Ipsum publishing software like
                    Aldus PageMaker.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                    has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                    of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also
                    the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing software like Aldus PageMaker including versions of Lorem Ipsum publishing software like Aldus
                    PageMaker.unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem
                    Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions
                    of Lorem Ipsum publishing software like Aldus PageMaker.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;
