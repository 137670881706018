import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({children}) => {
    return (
        <div className=" text-primary font-sans">
            <Header/>
            <div className="pt-14">
                {children}
            </div>
            <Footer/>
        </div>
    );
};

export default Layout;
