import React from 'react';
import HeaderSection from "../Components/HeaderSection";
import Services from "../Components/Services";
import WebDev from "../Components/WebDev";
import bag from "../assets/bag.png";

const HomePage = () => {
    return (
        <div className='flex flex-col w-full relative overflow-hidden'>

            <div className='left-0 top-0 w-full h-full -z-10'>
                <img
                    src={bag}
                    className='absolute opacity-80 blur-lg '
                    style={{
                        height: '700px',
                        right: '-24%',
                        top: '35%'
                    }}
                />


                <img
                    src={bag}
                    className='absolute opacity-60 blur-lg -z-10'
                    style={{
                        height: '700px',
                        right: '-24%',
                        top: '70%'
                    }}
                />


                <img
                    src={bag}
                    className='absolute opacity-70 blur-md -z-10'
                    style={{
                        height: '800px',
                        left: '-20%',
                        top: '40%'

                    }}
                />

                <img
                    src={bag}
                    className='absolute opacity-40 blur-lg -z-10'
                    style={{
                        height: '800px',
                        left: '-35%',
                        top: '80%'
                    }}
                />


                <img
                    src={bag}
                    className='absolute opacity-40 blur-lg -z-10'
                    style={{
                        height: '800px',
                        left: '-30%',
                        top: '50%'
                    }}
                />

            </div>


            <div>
                <HeaderSection/>
                <Services/>
                <WebDev/>

            </div>
        </div>
    );
};

export default HomePage;
