import React from 'react';
import bag from "../assets/bag.png";

const AboutUsPage = () => {
    return (

        <div className='flex flex-col w-full relative overflow-hidden'>

            <div className='left-0 top-0 w-full h-full -z-10'>
                <img
                    src={bag}
                    className='absolute opacity-80 blur-lg'
                    style={{
                        height: '700px',
                        right: '-24%',
                        top: '-100%'
                    }}
                />


                <img
                    src={bag}
                    className='absolute opacity-80 blur-lg'
                    style={{
                        height: '800px',
                        left: '-30%',
                    }}
                />

            </div>


            <div className='py-6 px-6 sm:py-10 sm:mx-10 md:py-16 md:px-16 lg:py-20 lg:px-20 flex flex-col space-y-2 sm:space-y-4 md:space-y-6 lg:space-y-8 '>
                <div className="flex items-center ">
                    <div className="w-10 h-10 bg-secondary -mr-6"></div>
                    <h1 className="text-primary font-medium text-xl md:text-2xl">About Us!</h1>

                </div>
                <p className='text-xl md:text-2xl leading-10 '>
                    A distinguished technical team that seeks to transform future technology into a contemporary
                    reality. We have extensive experience and comprehensive knowledge in all aspects of software
                    development, and we are distinguished by providing high-quality services that always exceed our
                    customers' expectations.
                </p>
            </div>

        </div>
    );
};

export default AboutUsPage;