import Layout from "./Components/Layout.";
import HomePage from "./Pages/HomePage";
import AboutUsPage from "./Pages/AboutUsPage";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ScrollToTop from "./Components/ScrollToTop";
import ContactPage from "./Pages/ContactPage";
import PrivacyPolicyPage from "./Pages/PrivacyPolicyPage";
import JoinUsPage from "./Pages/JoinUsPage";
import OurProjectsPage from "./Pages/OurProjectsPage";
import ProjectPage from "./Pages/ProjectPage";

function App() {
    return (
        <Router>
            <ScrollToTop/>
            <Layout>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/about" element={<AboutUsPage/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
                    <Route path="/join-us" element={<JoinUsPage/>}/>
                    <Route path="/projects" element={<OurProjectsPage/>}/>
                    <Route path="/projects/:id" element={<ProjectPage/>}/>
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
