import React from 'react';
import click from '../assets/click.svg';
import cloude from '../assets/cloude.svg';
import mobile from '../assets/mobile.svg';
import dev from '../assets/dev.svg';

const Services = () => {
    const services = [
        {
            title: <p>Digital <span className='font-medium'>transformation</span> of companies</p>,
            icon: <img src={click} className="h-20 w-20"/>
        },
        {
            title: <p>Cloud <span className='font-medium'>servers</span></p>,
            icon: <img src={cloude} className="h-20 w-20"/>
        },
        {
            title: <p>Mobile <span className='font-medium'>application</span> development</p>,
            icon: <img src={mobile} className="h-20 w-20"/>
        },
        {
            title: <p>Web <span className='font-medium'>development</span></p>,
            icon: <img src={dev} className="h-20 w-20"/>
        },
    ];
    return (
        <section className='flex flex-col md:flex-row mt-8 md:mt-16 px-6 gap-10 md:gap-0 md:px-20 justify-between '>

            <div className='w-full text-xl sm:text-2xl md:text-3xl space-y-5 md:space-y-10 leading-normal	'>

                <div className="flex items-center">
                    <div className="w-10 h-10 bg-secondary -mr-6"></div>
                    <h1 className="text-primary">Our <span className="font-bold">Services</span></h1>
                </div>

                <h2>Going beyond <br/>what’s <span className='text-secondary'>possible</span></h2>
            </div>

            <div className='grid grid-cols-2 gap-6 sm:gap-10 uppercase font-sans'>
                {services.map((e) =>
                    <div
                        className='aspect-square items-center flex flex-col w-full h-full shadow-sm
                         justify-center p-5 text-center space-y-4 rounded-xl bg-[#F1F1F1] hover:bg-primary hover:text-white '>
                        {e.icon}
                        {e.title}
                    </div>
                )}
            </div>

        </section>
    );
};

export default Services;