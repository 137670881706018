import React from 'react';

import logo from '../assets/logo-blue.svg'
import bucketDynamic from '../assets/bucket-dynamic-02.png';
import painter from '../assets/painter.png';
import {Link} from "react-router-dom";

const Footer = () => {

    return (
        <div className='relative flex flex-col w-full bg-white  shadow-[0_-4px_10px_rgba(0,0,0,0.02)]'>
            <div className=' left-0 top-0 w-full h-full'>
                <img
                    src={bucketDynamic}
                    className='absolute  blur-md'
                    style={{
                        height: '100px',
                        right: '20px',
                        top: '5%',
                    }}
                />


                <img
                    src={painter}
                    className='absolute  blur-md'
                    style={{
                        height: '200px',
                        bottom: '5%',
                    }}
                />
            </div>


            <div className='flex flex-col w-full bg-white'>

                <div
                    className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-y-10 md:gap-x-5 lg:gap-x-10 xl:gap-x-20 py-5 md:py-10 px-5 lg:px-10 xl:px-20">
                    <div className="hidden sm:block flex flex-col space-y-3 md:space-y-12 w-full items-center md:items-start
                    text-center md:text-start col-span-2 sm:col-span-3 md:col-span-5 lg:col-span-2 ">
                        <img src={logo} className="h-20 fill-red-950"/>
                        <p className="text-primary">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                    </div>

                    <div className="flex flex-col space-y-3 md:space-y-12 w-full items-start">
                        <h1 className="font-bold">About</h1>
                        <ul className="space-y-6">

                            <li>
                                <Link to="/" className="hover:font-bold">
                                    Main
                                </Link>
                            </li>

                            <li>
                                <Link to="/about" className="hover:font-bold">
                                    About Us
                                </Link>
                            </li>
                            <li>
                                <Link to="/projects">Apps</Link>
                            </li>
                            <li>
                                <Link to="/contact" className="hover:font-bold">
                                    Help & Support
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="flex flex-col space-y-3 md:space-y-12 w-full items-start">
                        <h1 className="font-bold">Company</h1>
                        <ul className="space-y-6">
                            <li>
                                <Link to="/privacy-policy" className="hover:font-bold">
                                    Privacy policy
                                </Link>
                            </li>
                            <li>Cookie policy</li>
                            <li>
                                <Link to="/join-us" className="hover:font-bold">
                                    Work with us
                                </Link>
                            </li>
                            <li>FAQ</li>
                        </ul>
                    </div>

                    <div className="flex flex-col space-y-3 md:space-y-12 w-full items-start col-span-2 sm:col-span-1 ">
                        <h1 className="font-bold">Contact Us</h1>
                        <ul className="space-y-6">
                            <li>
                                <a
                                    href="https://maps.app.goo.gl/bLR42KfM3rPHYWnX8?g_st=com.google.maps.preview.copy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover:underline"
                                >
                                    Makkah Al-Mukarramah - Al-Zaidi District - Social Development Bank - Avenue 30
                                </a>
                            </li>
                            <li>
                                <a href="tel:0580448276" className="hover:underline">
                                    0580448276
                                </a>
                            </li>
                            <li>
                                <a href="mailto:ask@solotec.sa" className="hover:underline">
                                    ask@solotec.sa
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>


                <div className='bg-primary text-white items-center text-center py-3 w-full text-sm sm:text-lg'>
                    <h1>Copyright © SOLO | All rights reserved.</h1>
                </div>
            </div>


        </div>
    );
};

export default Footer;





