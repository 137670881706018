import React from 'react';
import bag from "../assets/bag.png";
import tabVector from "../assets/tab-vector.svg";

const JoinUsPage = () => {
    return (
        <div className='flex flex-col w-full overflow-hidden relative pb-10'>

            <div className='left-0 top-0 w-full h-full -z-10'>
                <img
                    src={bag}
                    className='absolute opacity-80 blur-lg'
                    style={{
                        height: '50vh',
                        right: '-24%',
                        top: '-1%'
                    }}
                />

                <img
                    src={bag}
                    className='absolute opacity-80 blur-lg'
                    style={{
                        height: '60vh',
                        left: '-30%',
                    }}
                />

            </div>

            <div className='py-12 flex flex-col space-y-8 text-center px-8 sm:px-12 md:px-20 lg:px-40 xl:px-60 items-center'>
                <h1 className='text-2xl sm:text-3xl md:text-4xl font-medium'>
                    Join our growing team!
                </h1>
                <h1 className='text-base sm:text-lg md:text-xl font-medium'>
                    We love what we do and we do it with passion. We value the experimentation of the message and smart incentives.
                </h1>

                <button
                    type="submit"
                    className="bg-secondary mt-10 font-bold py-2 rounded-full text-primary px-8 sm:px-10"
                >
                    Work with us
                </button>

                <img src={tabVector} className="w-4/5 sm:w-3/5 md:w-2/5 lg:w-1/2 xl:w-1/2"/>

            </div>

        </div>
    );
};

export default JoinUsPage;
