import dashboard from "../assets/projects/data-analytics-system/dashboard.png";
import alburj1 from "../assets/projects/alburj/img1.png";
import alburj2 from "../assets/projects/alburj/img2.png";
import alburj3 from "../assets/projects/alburj/img3.png";
import alburj4 from "../assets/projects/alburj/img4.png";
import ithraa1 from "../assets/projects/ithraa/img_1.png";
import ithraa2 from "../assets/projects/ithraa/img.png";
import ithraa3 from "../assets/projects/ithraa/img_2.png";
import ithraa4 from "../assets/projects/ithraa/img_3.png";
import mawasem1 from "../assets/projects/mawasem/img.png";
import mawasem2 from "../assets/projects/mawasem/img_1.png";
import mawasem3 from "../assets/projects/mawasem/img_2.png";
import mawasem4 from "../assets/projects/mawasem/img_3.png";
import hajOs1 from "../assets/projects/hajOs/img.png";
import hajOs2 from "../assets/projects/hajOs/img_1.png";
import hajOs3 from "../assets/projects/hajOs/img_2.png";
import hajOs4 from "../assets/projects/hajOs/img_3.png";


const projects = [

    {
        id: 0,
        image: dashboard,
        title: 'Data Analysis System',
        summary: 'Hajj OS Application A Comprehensive Guide for Pilgrims',
        content: 'In today\'s data-driven world, businesses and organizations increasingly rely on robust Data Analysis Systems to gain valuable insights, drive decision-making, and maintain a competitive edge. A Data Analysis System is a sophisticated platform that collects, processes, analyzes, and visualizes vast amounts of data, transforming raw information into actionable intelligence.',
    },


    {
        id: 1,
        images: [
            alburj1,
            alburj2,
            alburj3,
            alburj4,
        ],
        title: 'Haj Application',
        summary: 'Hajj OS Application A Comprehensive Guide for Pilgrims',
        content: 'In today\'s data-driven world, businesses and organizations increasingly rely on robust Data Analysis Systems to gain valuable insights, drive decision-making, and maintain a competitive edge. A Data Analysis System is a sophisticated platform that collects, processes, analyzes, and visualizes vast amounts of data, transforming raw information into actionable intelligence.',
    },

    {

        id: 2,
        images: [
            ithraa1,
            ithraa2,
            ithraa3,
            ithraa4,
        ],
        title: 'Ithraa Al Khair application',
        summary: 'Hajj OS Application A Comprehensive Guide for Pilgrims',
        content: 'In today\'s data-driven world, businesses and organizations increasingly rely on robust Data Analysis Systems to gain valuable insights, drive decision-making, and maintain a competitive edge. A Data Analysis System is a sophisticated platform that collects, processes, analyzes, and visualizes vast amounts of data, transforming raw information into actionable intelligence.',
    },
    {
        id: 3,
        images: [
            mawasem1,
            mawasem2,
            mawasem3,
            mawasem4,
        ],
        title: 'Mwasem application',
        summary: 'Hajj OS Application A Comprehensive Guide for Pilgrims',
        content: 'In today\'s data-driven world, businesses and organizations increasingly rely on robust Data Analysis Systems to gain valuable insights, drive decision-making, and maintain a competitive edge. A Data Analysis System is a sophisticated platform that collects, processes, analyzes, and visualizes vast amounts of data, transforming raw information into actionable intelligence.',
    }, {

        id: 4,
        images: [
            hajOs1,
            hajOs2,
            hajOs3,
            hajOs4,
        ],
        title: 'Haj OS Application',
        summary: 'Hajj OS Application A Comprehensive Guide for Pilgrims',
        content: 'In today\'s data-driven world, businesses and organizations increasingly rely on robust Data Analysis Systems to gain valuable insights, drive decision-making, and maintain a competitive edge. A Data Analysis System is a sophisticated platform that collects, processes, analyzes, and visualizes vast amounts of data, transforming raw information into actionable intelligence.',
    },
];

export default projects;
