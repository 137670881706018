import React from 'react';
import logo from '../assets/logo.svg'
import menu from '../assets/menu.svg'
import {Link} from "react-router-dom";

const Header = () => {

    return (
        <header
            className='z-50 h-16 fixed w-full flex flex-row py-3 px-10 justify-between bg-primary'>
            <Link to={'/'}>
                <img src={logo} alt="Logo" className="h-8"/>
            </Link>
            <div className='flex flex-row space-x-5 text-white items-center cursor-pointer'>
                <h1>menu</h1>
                <img src={menu} alt="menu" className="h-2"/>
            </div>
        </header>
    );

};

export default Header;



